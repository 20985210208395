/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {Button} = _components;
  if (!Button) _missingMdxReference("Button", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "A﻿QUABION"), " to niezawodny a jednocześnie bezobsługowy system chroniący Twoje rury, armaturę, sprzęt AGD i obiekty produkcyjne przed osadzaniem się kamienia i rdzy."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Postaw na naturalną jakość wody!")), "\n", React.createElement(Button, {
    btnStyle: "color-dark",
    btnLink: "https://www.aquabionpolska.pl/",
    btnText: "Dowiedz się więcej"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
